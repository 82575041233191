<template>
  <DashboardLayout>
    <div class="page-content page-container" id="page-content">
      <div class="padding">
        <div class="row">
          <div class="col-lg-7">
            <div class="mb-4">
              <h4>Kullanıcı Bilgileri</h4>
              <div class="shadow-sm p-4 my-4 bg-body rounded">
                <div class="panel">
                  <div class="panel-body row">
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-12">
                          <p><span>İsim </span>: {{ user.first_name }}</p>
                        </div>
                        <div class="col-12">
                          <p><span>Soyad </span>: {{ user.last_name }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-12">
                          <p>
                            <span>Kullanıcı Adı </span>: {{ user.username }}
                          </p>
                        </div>
                        <div class="col-12">
                          <p><span>Email </span>: {{ user.email }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="createExchange"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      Yeni borsa hesabı ekle
                    </h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="İptal"
                    ></button>
                  </div>
                  <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                  >
                    <div class="modal-body row g-3">
                      <div class="col-md-6">
                        <label for="exchange" class="form-label">Borsa</label>
                        <Field
                          as="select"
                          name="exchange"
                          id="exchange"
                          class="form-select"
                          :class="{ 'is-invalid': errors.exchange }"
                        >
                          <option value="" selected disabled>Seçim..</option>
                          <option value="Bitfinex">Bitfinex</option>
                          <option value="Binance">Binance</option>
                        </Field>
                        <ErrorMessage
                          name="exchange"
                          class="invalid-feedback"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="name" class="form-label">İsim </label>
                        <Field
                          as="input"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.name }"
                          name="name"
                          id="name"
                          placeholder="Borsanıza takma bir isim verin"
                        />
                        <ErrorMessage name="name" class="invalid-feedback" />
                      </div>
                      <div class="col-12">
                        <label for="apiKey" class="form-label">API KEY</label>
                        <Field
                          as="input"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.api_key }"
                          id="apiKey"
                          name="api_key"
                        />
                        <ErrorMessage name="api_key" class="invalid-feedback" />
                      </div>
                      <div class="col-12">
                        <label for="secretKey" class="form-label"
                          >SECRET KEY</label
                        >
                        <Field
                          as="input"
                          type="text"
                          class="form-control"
                          :class="{ 'is-invalid': errors.secret_key }"
                          id="secretKey"
                          name="secret_key"
                        />
                        <ErrorMessage
                          name="secret_key"
                          class="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="reset"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                        id="exchangeDismiss"
                      >
                        İptal
                      </button>
                      <button type="submit" class="btn btn-primary">
                        Ekle
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>

            <div>
              <h4>Borsa Hesapları</h4>
              <div class="shadow-sm p-4 my-4 bg-body rounded">
                <button
                  type="button"
                  class="btn btn-outline-dark"
                  data-bs-toggle="modal"
                  data-bs-target="#createExchange"
                >
                  <i class="bi bi-plus"></i> Yeni ekle
                </button>
                <hr />
                <Exchanges
                  :exchanges="exchanges"
                  :update="update_exchange"
                  :_delete="delete_exchange"
                ></Exchanges>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <h4>
              İşlem Kayıtları
              <span class="fs-6 fw-light">(Son 10 tane gösterilir)</span>
            </h4>
            <div class="timeline block shadow-sm p-4 my-4 bg-body rounded">
              <Timeline
                v-for="log in logs"
                :key="log.id"
                v-bind="log"
                :title="log.action"
                :time="convertTime(log.action_date)"
              ></Timeline>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/Dashboard.layout.vue";
import Timeline from "@/components/Timeline.vue";
import Exchanges from "@/components/Exchanges.vue";

import { mapState, mapActions } from "vuex";

import moment from "moment";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

export default {
  data() {
    return {};
  },
  components: {
    DashboardLayout,
    Timeline,
    Form,
    Field,
    ErrorMessage,
    Exchanges,
  },
  created() {
    this.getLogs({ url: "/account/logs/" });
    this.getExchanges({ url: "/exchange/" });
  },
  methods: {
    ...mapActions("account", ["getLogs"]),
    ...mapActions("exchange", [
      "getExchanges",
      "getExchange",
      "deleteExchange",
      "updateExchange",
      "createExchange",
    ]),
    convertTime(date) {
      return moment(date).format("LLLL");
    },
    update_exchange(id, data) {
      this.updateExchange({ pk: id, data: data });
    },
    get_exchange(id) {
      this.getExchange({ pk: id });
    },
    delete_exchange(id) {
      this.deleteExchange({ pk: id });
    },
    onSubmit(values) {
      this.createExchange({ data: values }).then(() =>
        document.getElementById("exchangeDismiss").click()
      );
    },
  },
  computed: {
    ...mapState("account", ["logs", "user"]),
    ...mapState("exchange", ["exchanges"]),
  },
  setup() {
    const schema = Yup.object().shape({
      exchange: Yup.string().required("Zorunlu alan!"),
      name: Yup.string().required("Zorunlu alan!"),
      api_key: Yup.string().required("Zorunlu alan!"),
      secret_key: Yup.string().required("Zorunlu alan!"),
    });

    return {
      schema,
    };
  },
};
</script>

<style>
.w-32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 0.85em;
}

.tl-item .avatar {
  z-index: 2;
}

.circle {
  border-radius: 500px;
}

.gd-warning {
  color: #fff;
  border: none;
  background: #f4c414 linear-gradient(45deg, #f4c414, #f45414);
}

.tl-item {
  border-radius: 3px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
}

.tl-item > * {
  padding: 10px;
}

.tl-item .avatar {
  z-index: 2;
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-item:last-child .tl-dot:after {
  display: none;
}

.tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
}

.tl-dot:after,
.tl-dot:before {
  content: "";
  position: absolute;
  border-color: inherit;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.tl-dot:after {
  width: 0;
  height: auto;
  top: 25px;
  bottom: -15px;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-radius: 0;
}

tl-item.active .tl-dot:before {
  border-color: #448bff;
  box-shadow: 0 0 0 4px rgba(68, 139, 255, 0.2);
}

.tl-content p:last-child {
  margin-bottom: 0;
}

.tl-date {
  font-size: 0.85em;
  margin-top: 2px;
  min-width: 100px;
  max-width: 150px;
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.b-warning {
  border-color: #f4c414 !important;
}

.b-primary {
  border-color: #448bff !important;
}

.b-danger {
  border-color: #f54394 !important;
}
</style>
