<template>
  <div>
    <div
      class="modal fade"
      id="detailExchange"
      tabindex="-1"
      aria-labelledby="detailExchange"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="detailExchange">
              {{ exchangeDetail.name }} / <b>{{ exchangeDetail.exchange }}</b>
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="İptal"
            ></button>
          </div>
          <div class="modal-body row g-3">
            <div class="col-12">API Key: {{ exchangeDetail.api_key }}</div>
            <div class="col-12">
              Secret Key: {{ exchangeDetail.secret_key }}
            </div>
          </div>
          <div class="modal-footer justify-content-start">
            <p>
              Oluşturulma tarihi: {{ convertTime(exchangeDetail.create_date) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="updateExchange"
      tabindex="-1"
      aria-labelledby="updateExchange"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="updateExchange">Güncelleme</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="İptal"
            ></button>
          </div>
          <Form
            @submit="onSubmit"
            :validation-schema="schema"
            v-slot="{ errors }"
          >
            <div class="modal-body row g-3">
              <div class="col-md-6">
                <label for="exchange" class="form-label">Borsa</label>
                <Field
                  as="select"
                  name="exchange"
                  class="form-select"
                  :class="{ 'is-invalid': errors.exchange }"
                  id="exchange"
                  v-model="exchangeDetail.exchange"
                >
                  <option :value="exchangeDetail.exchange" selected>
                    {{ exchangeDetail.exchange }}
                  </option></Field
                >
                <ErrorMessage name="exchange" class="invalid-feedback" />
              </div>
              <div class="col-md-6">
                <label for="name" class="form-label">İsim</label>
                <Field
                  as="input"
                  type="text"
                  name="name"
                  v-model="exchangeDetail.name"
                  class="form-control"
                  :class="{ 'is-invalid': errors.name }"
                  id="name"
                />
                <ErrorMessage name="name" class="invalid-feedback" />
              </div>
              <div class="col-12">
                <label for="api_key" class="form-label">API KEY</label>
                <Field
                  as="input"
                  type="text"
                  name="api_key"
                  v-model="exchangeDetail.api_key"
                  class="form-control"
                  :class="{ 'is-invalid': errors.api_key }"
                  id="api_key"
                />
                <ErrorMessage name="api_key" class="invalid-feedback" />
              </div>
              <div class="col-12">
                <label for="secret_key" class="form-label">SECRET KEY</label>
                <Field
                  as="input"
                  type="text"
                  name="secret_key"
                  v-model="exchangeDetail.secret_key"
                  class="form-control"
                  :class="{ 'is-invalid': errors.secret_key }"
                  id="secret_key"
                />
                <ErrorMessage name="secret_key" class="invalid-feedback" />
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                id="updateDismiss"
              >
                İptal
              </button>
              <button type="submit" class="btn btn-primary">Güncelle</button>
            </div>
          </Form>
        </div>
      </div>
    </div>
    <div v-if="exchanges.length !== 0">
      <div class="row" v-for="exchange in exchanges" :key="exchange.id">
        <div class="col-md-9">
          <b>{{ exchange.name }} / {{ exchange.exchange }}</b
          ><br />
          <p class="text-wrap">Api Key: {{ exchange.api_key }}</p>
        </div>
        <div class="col-md-3">
          <div class="btn-group" role="group" aria-label="">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#detailExchange"
              @click="get(exchange)"
            >
              <i class="bi bi-eye"></i>
            </button>
            <button
              type="button"
              class="btn btn-warning"
              data-bs-toggle="modal"
              data-bs-target="#updateExchange"
              @click="get(exchange)"
            >
              <i class="bi bi-pencil"></i>
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="_delete(exchange.id)"
            >
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-else>
      <p class="text-center h4">Borsa hesabınız bulunmuyor</p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { Field, Form, ErrorMessage } from "vee-validate";
import * as Yup from "yup";

export default {
  name: "Exchanges",
  components: { Form, Field, ErrorMessage },
  data() {
    return {
      exchangeDetail: {},
    };
  },
  props: ["exchanges", "_delete", "update"],
  methods: {
    get(exchange) {
      this.exchangeDetail = exchange;
    },
    convertTime(date) {
      return moment(date).format("LL");
    },
    onSubmit(values) {
      this.update(this.exchangeDetail.id, values);
      document.getElementById("updateDismiss").click();
    },
  },
  setup() {
    const schema = Yup.object().shape({
      exchange: Yup.string().required("Boş bırakılamaz!"),
      name: Yup.string().required("Boş bırakılamaz!"),
      api_key: Yup.string().required("Boş bırakılamaz!"),
      secret_key: Yup.string().required("Boş bırakılamaz!"),
    });

    return {
      schema,
    };
  },
};
</script>
