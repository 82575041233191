<template>
  <div class="tl-item">
    <div class="tl-dot" :class="'b-' + variant"></div>
    <div class="tl-content">
      <div class="fs-5">{{ title }}</div>
      <div class="tl-date text-muted mt-1">{{ time }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Timeline",
  props: {
    title: {
      type: String,
      required: true,
    },
    time: { default: "" },
    variant: {
      type: String,
      default: "primary",
    },
  },
});
</script>

<style></style>
